.ContactBtn {
    background-color: transparent;
    color: #333333;
    font-size: 20px;
    font-family: inherit;
    font-weight: bold;
    border: solid teal 3px;
    border-radius: 50px;
    box-shadow: 0 0 5px #333333;
    padding: 8px 64px 8px 64px;
    transition-property: background-color, box-shadow, color, padding;
    transition-duration: 0.1s;
    transition-timing-function: linear;
}

.ContactBtn:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 10px #333333;
    transition-property: background-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    cursor: pointer;
}