.ProjectLinks {
    display: flex;
    gap: 4px;
}

.ProjectLinks-icon {
    font-size: 20px;
}

@media only screen and (max-width: 768px) {

    .ProjectLinks-icon {
        font-size: 12px;
    }

}