.NavBar {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NavBar-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.NavBar-title {
    font-weight: bold;
}