.App {
    display: flex;
    justify-content: center;
}

.App-content {
    width: 1000px;
}

#landing-page {
    height: 100vh;
}

.Header {
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Header-content {
    width: fit-content;
}

.Header-title {
    font-size: 64px;
    line-height: 1;
    width: fit-content;
}

.Header-subtitle {
    font-size: 32px;
    color: #444444;
    width: fit-content;
}

.Header-para {
    margin-top: 8px;
    font-size: 24px;
    line-height: 1.75;
    max-width: 600px;
}

.Header button {
    margin-top: 30px;
}

#experience {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#experience-timeline {
    margin-top: 100px;
    margin-bottom: 100px;
}

.Tech-container {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.Tech-row {
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.Tech-row:last-child {
    margin-bottom: 0;
}

.Tech-icon {
    font-size: 100px;
    width: 100px;
    height: 100px;
    color: rgb(28, 28, 32);
    color: #333333;
}

.projects-container {
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 100px;
}

.Project {
    width: 100%;
    height: 576px;
    border-radius: 16px;
    box-shadow: 8px 8px 12px 4px #444444;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url('./images/trading_sim.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.Project-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    position: relative;
    opacity: 0;
    overflow: hidden;
    transition-property: background-color, opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    z-index: 1;
}

.Project:hover .Project-wrapper {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
    transition-property: background-color, opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease;
}

.Project:hover .Project-content {
    opacity: 1;
    bottom: 36px;
    transition-property: opacity, bottom;
    transition-duration: 0.5s;
    transition-timing-function: ease;
}

.Project-content {
    width: 66%;
    height: 75%;
    
    opacity: 0;
    bottom: -512px;
    left: 5%;
    padding: 24px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;

    transition-property: opacity, bottom;
    transition-duration: 0.5s;
    transition-timing-function: ease;
}

.Project-head {
    position: relative;
    padding-bottom: 8px;
    border-bottom: solid teal 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Project-name {
    font-size: 40px;
    color: rgb(0, 255, 255);
}

.Project-description {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-top: 4px;
    width: 80%;
    font-size: 18px;
    line-height: 1.5em;
    color: white;
}

.Project-techs {
    display: flex;
    gap: 10px;
}

.Project-icon {
    font-size: 40px;
    color: white;
}

footer {
    color: black;
    padding: 32px;
    padding-bottom: 12px;
}

.Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Footer-socials {
    text-align: center;
    width: 100px;
    margin-top: 64px;
    margin-bottom: 64px;
    font-size: 20px;
}

.Footer-bottom {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.bottom-text {
    width: 25%;
    font-weight: bold;
    color: black;
}

.bottom-left {
    text-align: right;
}

.bottom-middle {
    text-align: center;
}

.bottom-right {
    text-align: left;
}
