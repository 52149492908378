.Experience {
    max-width: 640px;
    box-shadow: 0 0 8px black;
    transition-property: box-shadow;
    transition-duration: 0.25s;
    transition-timing-function: ease;
}

.Experience:hover {
    box-shadow: 0 0 20px black;
    transition-property: box-shadow;
    transition-duration: 0.25s;
    transition-timing-function: ease;
}

.Exp-content {
    background-color: #153538;
    opacity: 1;
    padding: 24px;
    color: whitesmoke;
}

.Exp-title {
    position: relative;
    margin-bottom: 8px;
}

.Exp-job-title {
    font-size: 30px;
    color: rgb(0, 255, 255);
    font-weight: normal;
}

.Exp-company {
    font-size: 20px;
    color: #999999;
    font-weight: normal;
}

.Exp-date {
    position: absolute;
    bottom: 0;
    right: 0;
    font-style: italic;
}

.Exp-summary {
    font-size: 18px;
    padding-top: 8px;
    border-top: solid teal 2px;
    margin-bottom: 16px;
    color: #EEEEEE;
    line-height: 1.75;
}

.Exp-skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.Exp-Skill {
    background-color: rgb(28, 28, 32);
    box-shadow: 1px 2px 4px rgb(0, 0, 0);
    width: fit-content;
    padding: 4px 8px 4px 8px;
    cursor: default;
    font-size: 14px;
    letter-spacing: 0.5px;
}

@media only screen and (max-width: 1080px) {

    .Exp-date {
        position: initial;
        bottom: initial;
        right: initial;
    }

}