@media only screen and (max-width: 1080px) {

    /* App */

    .App-content {
        width: 100%;
    }

    /* Landing Page */

    .Header {
        margin-top: 10%;
    }
    
    .Header-content {
        margin-left: 8px;
        margin-right: 8px;
    }

    .projects {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /* Experience Timeline */

    #experience-timeline {
        margin: 100px 30px 100px 30px;
    }

    /* Projects */

    #projects {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .projects-container {
        width: 80%;
        justify-self: center;
    }

}

@media only screen and (max-width: 768px) {

    /* Projects */
    .Project:hover .Project-content {
        bottom: 0;
    }

    .Project-name, .Project-icon {
        font-size: 20px;
    }
    
    .Project-techs {
        gap: 5px;
    }
    
    .Project-description {
        font-size: 12px;
    }

    /* Footer */
    .bottom-text {
        font-size: 12px;
    }

}