.ContactForm {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
}

.ContactForm-input, .ContactForm-btn {
    font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    width: 400px;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    padding: 6px 4px 6px 4px;
    border: solid teal 2px;
}

.ContactForm-input:focus {
    border-color: red;
}

.ContactForm-btn {
    width: 412px;
}

.ContactForm-btn:hover {
    cursor: pointer;
}