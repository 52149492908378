.Timeline-container {
    padding-left: 100px;
    position: relative;
}

.Timeline-container::before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 4px;
    height: calc(100% + 100px);
    background-color: black;
    border-radius: 50px;
}

.Timeline {
    list-style: none;
}

@media only screen and (max-width: 1080px) {

    .Timeline-container {
        padding-left: 40px;
    }
}