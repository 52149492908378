.TimelineItem {
    position: relative;
}

.TimelineItem::before {
    content: '';
    position: absolute;
    top: calc(50% - 8px);
    left: -106px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: black;
}

.TimelineItem:nth-child(n) {
    margin-bottom: 25px;
}

.TimelineItem:last-child {
    margin-bottom: 0;
}

@media only screen and (max-width: 1080px) {

    .TimelineItem::before {
        left: -46px;
    }
}